@import "definitions";

$search_entry_action_pad: 1.2em 2em;      // Innenabstand Sucheintragsfunktion
$search_content_bg: transparent;          // Reiterobjekt Hintergrundfarbe
$search_head_dark_bg: $theme_color;          // Reiter Farbe
$search_head_dark_font_color: $theme_font_color; // Reiter Schritfarbe


div.search_head > .label {
    display: inline-block;
    vertical-align: top;
    font-size: $font_size_heading;
    .arrow{ 
        @include sl-icon($fa-var-angle-down);
        margin-left: $mar_tiny;
    }
}

div.search div.tabs div.tabs_head > div:last-child:first-child{
    background-color: $search_head_dark_bg;
    color: $search_head_dark_font_color;
    border: none;
}

div.search .tabs .tabs_content{ 
    padding: 0 $pad $pad_small $pad;
    margin-bottom: $mar_big;
    background: $search_content_bg;
    box-shadow: none;
    border-right: $border;
    border-bottom: $border;
    form{
        margin-bottom: 0;
        padding: $pad;
        padding-top: .5em;
        display: flex;
        flex-wrap: wrap;

        > div {
            display: inline-table;
            vertical-align: top;
        }

        >* {
            margin-top: $pad;
        }
    }
    div.form-group {
        display: flex;
        padding-right: $pad;
        height: 2.4em;
        align-items: center;
        > label{
            padding-right: $pad_small;
            display: table-cell;
        }
        > div.input > span {
            margin-right: $mar_tiny;
            > input[type="checkbox"] {
                vertical-align: middle;
            }
        }
    }
    div.input {
        display:table-cell;
    }
    div.current_filters {
        padding-left: $pad;
        padding-right: $pad;
        button.button {
            vertical-align: middle;
        }
        span.current_filter {
            padding-right: $pad;
            .filter_delete {          
                @include sl-icon($fa-var-close);
            }
        }
    }
}

/*Eintragseditor*/

.search_entry_action{
    padding: $search_entry_action_pad;
    border: $border;
    display: inline-block;
    margin-bottom: $mar_big;
}

div.search_head>.label{
    margin-bottom: 0; 
    display: inline-block;
    vertical-align: top;
}

div.search_head>i{
    font-size: $font_size_heading;
    margin: $mar_tiny 0 0 $mar_tiny;
}

.search_entry_action form > div {
    display:inline;
}

.search_entry_action div.form-group {
    display:inline;
    margin-right: $mar;
}

.search_entry_action div.form-group>label{
    padding-right: $pad_small;
}

.search_entry_action div.form_field {
    display:inline;
}



/*Suche im Dialog*/

div.search_body {
    div.form-group{
        margin-bottom: $mar;
        div.input{
            display: inline-block;
        }
    }
}

/*Suchfilter*/

.current_filters{
    .current_filter{
        display: inline-block;
        cursor: pointer;
        padding: $pad_small;
        border: $border;
        margin: 0 $mar_small;
        i.filter_delete{
            margin-left: $mar_small;
            font-size: $big_icon_size;
        }
        .input{
            margin-left: $mar_tiny;
        }
    }

    &.is-no-form {
        padding-top: $mar_small;      
        .current_filter{
            cursor: default;
        }  
    }
}